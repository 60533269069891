import React, { useState } from "react";
import { MdCircle } from "react-icons/md";
import { Modal, Avatar, Image } from "antd";
import ID from "../../Assets/images/ID.png";
import Backview from "../../Assets/images/backview.png";

export default function Livness({ data }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const showModal = (image) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const userInfo = {
    name: `${data?.data?.profile?.user__first_name ?? ""} ${
      data?.data?.profile?.user__last_name ?? ""
    }`,
    profilePic: (
      <div className="h-[50px] w-[50px]">
        <Avatar src={data?.data?.profile?.user__image} size="large">
          {data?.data?.profile?.user__first_name?.charAt(0)}
          {data?.data?.profile?.user__last_name?.charAt(0)}
        </Avatar>
      </div>
    ),
    credentials: [
      {
        id: "1",
        credential: ID,
        backview: Backview,
        type: "National Identity Number",
      },
    ],
  };

  const Data = [
    {
      position: "FORWARD",
      image: data?.data?.facials?.straight,
      quality: "55%",
      time: "06/08/2024, 11:44am",
    },
    {
      position: "RIGHT",
      image: data?.data?.facials?.right,
      quality: "55%",
      time: "06/08/2024, 11:44am",
    },
    {
      position: "LEFT",
      image: data?.data?.facials?.left,
      quality: "55%",
      time: "06/08/2024, 11:44am",
    },
    {
      position: "FACE UP",
      image: data?.data?.facials?.up,
      quality: "55%",
      time: "06/08/2024, 11:44am",
    },
    {
      position: "FACE DOWN",
      image: data?.data?.facials?.down,
      quality: "55%",
      time: "06/08/2024, 11:44am",
    },
  ];

  return (
    <div className="bg-gray-100 h-[calc(100vh-140px)] overflow-y-scroll">
      <div className="px-5 py-3 border-b-[2px] border-b-gray-300 bg-white md:flex items-center justify-between gap-5">
        <div className="md:flex items-center gap-3">
          <div className="flex items-center gap-5">
            {userInfo.profilePic}
            <div className="w-full font-bold">{userInfo.name}</div>
          </div>
          {/* <div className="flex md:justify-start justify-center items-center gap-10">
            <small className="flex items-center gap-2">
              <span className="text-green-700 font-bold">Verified</span>{" "}
              <span className="font-bold text-gray-500">5mins ago</span>
            </small>
            <small className="flex items-center gap-5">
              <span>
                Fraud Score{" "}
                <span className="text-violet-800 font-bold text-lg">65%</span>
              </span>
            </small>
          </div> */}
        </div>

        <div className="flex md:justify-start justify-center items-center gap-3">
          <span>Status:</span>
          <small className="flex items-center gap-2 py-1 px-2 text-blue-600 bg-blue-100 rounded-[15px]">
            <MdCircle size={10} />
            {data?.data?.profile?.user__verification_status}
          </small>
        </div>
      </div>
      <div className="px-5 pb-5">
        <section className="py-3">
          <div className="flex items-center gap-2">
            <span className="font-bold">Liveness </span>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            {Data.map((item, index) => (
              <div key={index} className="p-3 w-full bg-white rounded-md">
                <small className="text-blue-900 font-bold pb-2">
                  {item.position}
                </small>
                <div className="flex">
                  <div className="h-[300px] overflow-hidden">
                    <Image className="w-full" src={item.image} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width={900}
        centered
      >
        <div className="flex flex-col gap-4">
          <span className="text-xl font-bold">Image</span>
          <div className="flex items-center gap-2">
            <small className="font-bold">Status:</small>
            <small className="bg-red-50 font-medium text-red-500 px-2 rounded-[15px]">
              Rejected
            </small>
            <small className="bg-violet-50 font-medium text-violet-500 px-2 rounded-[15px]">
              Duplicate
            </small>
          </div>
          <img
            src={selectedImage}
            alt="Selected"
            className="object-cover"
            style={{ width: "100%", height: "400px" }}
          />
        </div>
      </Modal>
    </div>
  );
}

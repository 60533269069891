import { Modal } from "antd";

const ModalComponent = ({ width, onCancel, component, closeIcon, showDialog, setShowDialog }) => {
  return (
    <Modal
      centered
      footer={null}
      open={showDialog}
      onCancel={onCancel}
      width={width || 450}
      closeIcon={closeIcon}
      onOk={() => setShowDialog(false)}
    >
      {component}
    </Modal>
  );
};

export default ModalComponent;

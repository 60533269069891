import axios from "axios";
import axiosInstance from "../../Util/config";

export const getReq = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  return axiosInstance
    .get(`${route}`, { params })
    .then(({ data }) => data)
    .catch((error) => {});
};

export const getPublicReq = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  return axios.get(`${route}`, { params }).then((res) => res.data);
};

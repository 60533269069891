import { MdCircle } from "react-icons/md";
import { Avatar, Image } from "antd";
import React from "react";
import ID from "../../Assets/images/ID.png";
import Backview from "../../Assets/images/backview.png";

export default function FaceMatch({ data }) {
  const userInfo = {
    name: `${data?.data?.profile?.user__first_name ?? ""} ${data?.data?.profile?.user__last_name ?? ""}`,
    profilePic: (
      <div className="h-[50px] w-[50px]">
        <Avatar src={data?.data?.profile?.user__image} size="large">
          {data?.data?.profile?.user__first_name?.charAt(0)}
          {data?.data?.profile?.user__last_name?.charAt(0)}
        </Avatar>
      </div>
    ),
    credentials: [
      {
        id: "1",
        credential: ID,
        backview: Backview,
        type: "National Identity Number",
      },
    ],
  };

  const Selfie = [
    {
      type: "Identity Card",
      image: data?.data?.facials?.straight,
      quality: "High",
      time: "06/08/2024, 11:44am",
    },
    {
      type: "Selfie Shot",
      image: data?.data?.facials?.up,
      quality: "High",
      time: "06/08/2024, 11:44am",
    },
  ];

  return (
    <div className="bg-gray-100 h-[calc(100vh-140px)] overflow-y-scroll">
      <div className="px-5 py-3 border-b-[2px] border-b-gray-300 bg-white md:flex items-center justify-between gap-5">
        <div className="md:flex items-center gap-3">
          <div className="flex items-center gap-5">
            {userInfo.profilePic}
            <div className="w-full font-bold">{userInfo.name}</div>
          </div>
        </div>
        <div className="flex md:justify-start justify-center items-center gap-3">
          <span>Status:</span>
          <small className="flex items-center gap-2 py-1 px-2 text-blue-600 bg-blue-100 rounded-[15px]">
            <MdCircle size={10} />
            {data?.data?.profile?.user__verification_status}
          </small>
        </div>
      </div>
      <div className="px-5 pb-5">
        <section className="py-3">
          <div className="flex items-center gap-2">
            <span className="font-bold">Face Match </span>
          </div>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="py-3 md:flex flex flex-col items-center flex-wrap gap-4">
              <p className="text-orange-500 text-[14px] font-semibold">Identity Card Extracts</p>
              {data?.data?.extractions_?.map((item, index) => (
                <div key={index} className="w-full rounded-md">
                  <Image alt="" height={300} width={"100%"} src={item.image} className="w-full object-cover" />
                </div>
              ))}
            </div>
            <div className="py-3 md:flex flex flex-col items-center flex-wrap gap-4">
              <p className="text-blue-800 text-[14px] font-semibold">Selfie Shot</p>
              {Selfie.map((item, index) => (
                <div key={index} className="w-full rounded-md">
                  <Image alt="" height={300} width={"100%"} src={item.image} className="w-full object-cover" />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

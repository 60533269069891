import { Toaster } from "sonner";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import router from "./routes";
import "./index.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster richColors position="top-right" />
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          components: {
            Calendar: {
              monthControlWidth: 40,
              yearControlWidth: 40,
              miniContentHeight: 100,
              /* here is your component tokens */
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
reportWebVitals();

import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { postReq } from "../../../Services/Mutation/Mutation";
import LogoutIcon from "../../../Assets/images/logout.svg";
import queryString from "query-string";

export const BlacklistUser = ({ showDialog, setShowDialog }) => {
  const { search } = useLocation();
  const { zin } = queryString.parse(search);
  const queryClient = useQueryClient();

  // Queries & Mutation
  const mutation = useMutation({
    mutationFn: postReq,
  });

  const handleSubmit = () => {
    mutation
      .mutateAsync({
        payload: {
          type: "blacklist",
        },
        route: `/vendors/users/${zin}`,
      })
      .then(() => {
        queryClient.refetchQueries(["get-single-use"]);
        setShowDialog(!showDialog);
      })
      .catch((error) => {});
  };

  return (
    <div className="w-[100%]">
      <div>
        <img src={LogoutIcon} className="mx-auto" alt="" />
        <p className="text-[24px] text-[#0F172A] font-cereal_bold text-center my-[1rem]">Blacklist User</p>
        <p className="text-[#222222] font-cereal text-[12px] text-center">
          Are you sure you want to blacklist this user?
        </p>
        <button
          type="submit"
          onClick={handleSubmit}
          className="h-[54px] w-[100%] bg-danger text-secondary rounded-[4px] font-cereal_medium text-[14px] my-[1.5rem]"
        >
          {mutation.isPending ? <LoadingOutlined /> : "Yes, Proceed"}
        </button>
        <p
          className="text-[#222222] font-cereal text-[12px] text-center mb-5 cursor-pointer"
          onClick={() => setShowDialog(!showDialog)}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};

import React from "react";
import { postReq } from "../../../Services/Mutation/Mutation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Field, Form, Formik } from "formik";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const ExtractedData = ({ data, showDialog, setShowDialog }) => {
  const { search } = useLocation();
  const { zin } = queryString.parse(search);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: postReq,
  });

  const handleSubmit = (values) => {
    const payload = {
      type: "edit",
      ocr_id: data?.id,
      replacement: values,
    };
    mutation
      .mutateAsync({
        payload,
        route: `/vendors/users/${zin}`,
      })
      .then((res) => {
        queryClient.refetchQueries(["get-single-use"]);
        setShowDialog(!showDialog);
      })
      .catch((error) => {});
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-[1.5rem]">
        <p className="text-[20px] text-[#222222] font-cereal_bold text-center">Edit Extracted Details</p>
        <IoCloseCircleOutline style={{ cursor: "pointer" }} size={30} onClick={() => setShowDialog(!showDialog)} />
      </div>
      <div className="h-[500px] overflow-y-scroll">
        <Formik
          initialValues={data?.ocr_details}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          enableReinitialize
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {(props) => (
            <Form className="relative">
              {Object.keys(data?.ocr_details).map((key) => (
                <div className="mb-4" key={key}>
                  <label className="block text-[#222222] text-[12px] font-cereal_medium">{key}</label>
                  <Field
                    type="text"
                    name={key}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    className={`${
                      props.touched[key] && Boolean(props.errors[key]) && "border-b border-b-[red]"
                    } h-[50px] w-[100%] font-cereal px-4 outline-none border-[1px] border-[#EDEFEB] rounded-[4px]`}
                  />
                  {props.touched[key] && props.errors[key] && (
                    <div className="text-[12px] text-[red] mt-1">{props.errors[key]}</div>
                  )}
                </div>
              ))}

              <button
                type="submit"
                disabled={!props.isValid}
                className={`${
                  props.isValid ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                } h-[54px] w-[100%] bg-primary text-secondary rounded-[4px] font-cereal_medium text-[14px] mt-[2rem]`}
              >
                {mutation.isPending ? <LoadingOutlined /> : "Update details"}
              </button>

              <button
                type="button"
                onClick={() => setShowDialog(!showDialog)}
                className="h-[54px] w-[100%] text-red-500 rounded-[4px] font-cereal_medium text-[14px] mt-[0.6rem]"
              >
                Cancel
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ExtractedData;
